import mixpanel, { OverridedMixpanel } from 'mixpanel-browser'

let instance: OverridedMixpanel | undefined

function getMixpanel() {
  mixpanel.init('1ed5307330c3f39f366af4de9f3eb188', { debug: false, ignore_dnt: true })
  mixpanel.identify()
  mixpanel.track('Start')
  return mixpanel
}

export const useMixpanel = () => {
  if (!instance) {
    instance = getMixpanel()
  }
  return instance
}
