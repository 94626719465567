import React, { useEffect, useState } from 'react'
import {
  FeedbackHeader,
  FeedbackMessage,
  FeedbackText,
  FeedbackTextContainer,
  FormContainer,
  ButtonContainer,
} from './style'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useNavigate } from 'react-router-dom'
import { FeedbackScore } from './FeedbackScore'
import { useMixpanel } from '../../mixpanel'
import { Typography } from '../../components/Typography'
import { Button } from '../../components/Button'

export const Feedback = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedScore, setSelectedScore] = useState<number | null>(null)
  const [audioGuideScore, setAudioGuideScore] = useState<number | null>(null)
  const [exhibitionScore, setExhibitionScore] = useState<number | null>(null)
  const [feedbackMessage, setFeedbackMessage] = useState<string>('')
  const mixpanel = useMixpanel()

  const isUserListenedTour = useSelector((state: RootState) => state.user.isUserListenedTour)

  useEffect(() => {
    const isUserProvideFeedback = window.localStorage.getItem('isUserProvideFeedback')

    if (!isUserListenedTour || isUserProvideFeedback) {
      navigate('/', { replace: true })
    }
  }, [isUserListenedTour, navigate])

  useEffect(() => {
    if (selectedScore !== null) {
      mixpanel.track('Feedback: Overall Score', {
        score: selectedScore,
      })
      window.localStorage.setItem('isUserProvideFeedback', 'true')
    }
  }, [selectedScore, mixpanel, dispatch])

  const changeAudioGuideScore = (score: number | null) => {
    mixpanel.track('Feedback: Audio Guide Score', {
      score,
    })
    setAudioGuideScore(score)
  }

  const changeExhibitionGuideScore = (score: number | null) => {
    mixpanel.track('Feedback: Exhibition Score', {
      score,
    })
    setExhibitionScore(score)
  }

  const handleSubmit = () => {
    mixpanel.track('Feedback: Message', {
      message: feedbackMessage,
    })
    navigate('/', { replace: true })
  }

  return (
    <FormContainer isOpen={selectedScore !== null}>
      {selectedScore === null ? (
        <>
          <FeedbackTextContainer>
            <FeedbackHeader>How was your experience?</FeedbackHeader>

            <FeedbackText>
              We’ve been working hard on this audio tour, so your feedback is super helpful to us.
            </FeedbackText>
          </FeedbackTextContainer>
          <FeedbackScore selectedScore={selectedScore} setSelectedScore={setSelectedScore} />
        </>
      ) : (
        <>
          <Typography
            style={{
              color: '#18181b',
              fontWeight: 900,
              letterSpacing: -0.4,
            }}
            type={'header'}
          >
            How was your experience?
          </Typography>
          <Typography>Overall experience</Typography>
          <FeedbackScore selectedScore={selectedScore} setSelectedScore={setSelectedScore} />
          <Typography>How was the audio guide?</Typography>
          <FeedbackScore selectedScore={audioGuideScore} setSelectedScore={changeAudioGuideScore} />
          <Typography>Did you enjoy the exhibition?</Typography>
          <FeedbackScore
            selectedScore={exhibitionScore}
            setSelectedScore={changeExhibitionGuideScore}
          />
          <Typography>Share more about your experience:</Typography>
          <FeedbackMessage
            value={feedbackMessage}
            onChange={e => setFeedbackMessage(e.target.value)}
          ></FeedbackMessage>
          <ButtonContainer>
            <Button onClick={handleSubmit} type="contained">
              Submit
            </Button>
          </ButtonContainer>
        </>
      )}
    </FormContainer>
  )
}
