import styled from 'styled-components'

export const ChapterContainer = styled.div`
  display: flex;
  max-height: 80px;
  gap: 30px;
  cursor: pointer;
`

export const ChapterCover = styled.div`
  width: 80px;
  height: 80px;

  img {
    width: 80px;
    height: 80px;
  }
`

export const ChapterInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`

export const ChapterNumber = styled.span`
  color: #4838d1;
  font-size: 12px;
  font-weight: 400;
`
