import React, { useEffect, useRef, useState } from 'react'
import { Typography } from '../../components/Typography'
import { ImageComponent } from '../../components/Image'
import {
  TourPageContainer,
  ImageContainer,
  AudioContainer,
  DescriptionContainer,
  ChipContainer,
} from './style'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { AudioPlayer } from '../../components/AudioPlayer'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { selectChapter } from '../../redux/chaptersSlice'
import { useMixpanel } from '../../mixpanel'
import { Chip } from '../../components/Chip'
import Loader from '../../components/Loader'

const PROGRESS_UPDATE_IN_SECONDS = 20

export function TourPlayer() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const mixpanel = useMixpanel()
  const [searchParams] = useSearchParams()
  const chapterIdFromUrl = searchParams.get('tourStop')

  const chapters = useSelector((state: RootState) => state.chapters.chapters)
  const selectedChapter = useSelector((state: RootState) => state.chapters.selectedChapter)
  const progressContainer = useRef({} as Record<string, number>)
  const intervalRef = useRef<ReturnType<typeof setInterval>>()

  const [isTrackReady, setIsTrackReady] = useState(false)

  useEffect(() => {
    if (!selectedChapter) {
      if (chapterIdFromUrl && +chapterIdFromUrl) {
        const order = parseInt(chapterIdFromUrl)
        const chapter = chapters.find(el => el.order === order)
        if (chapter) {
          dispatch(selectChapter(chapter.id))
        }
        if (chapters.length && !chapter) {
          navigate('/')
        }
      } else {
        navigate('/')
      }
    }
    setIsTrackReady(false)
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalCallbackFunction()
      }
    }
  }, [selectedChapter, navigate, chapterIdFromUrl, dispatch, chapters])

  const handleTrackIsReady = (isTrackReady: boolean) => {
    mixpanel.track('Audio: Track loaded', {
      chapterId: selectedChapter?.id,
      chapterTitle: selectedChapter?.title,
    })
    setIsTrackReady(isTrackReady)
  }

  const handleNextChapterClick = (duration: number, currentProgress: number) => {
    const nextChapter =
      chapters[chapters.findIndex(chapter => chapter.id === selectedChapter!.id) + 1]
    mixpanel.track('Audio: Track end', {
      chapterId: selectedChapter?.id,
      chapterTitle: selectedChapter?.title,
      duration: Math.floor(duration),
      currentProgress: Math.floor(currentProgress),
    })
    if (nextChapter) {
      handleSelectChapter(nextChapter.id)
    } else {
      navigate('/feedback')
    }
  }

  const onLoaderFinish = (time: number) => {
    if (time) {
      mixpanel.track('Audio: Track Loaded Time', {
        time,
      })
    }
  }

  const handlePrevChapterClick = () => {
    const prevChapter =
      chapters[chapters.findIndex(chapter => chapter.id === selectedChapter!.id) - 1]
    if (prevChapter) {
      mixpanel.track('Audio: Prev track', {
        chapterId: selectedChapter?.id,
        chapterTitle: selectedChapter?.title,
        prevChapterId: prevChapter.id,
        prevChapterTitle: prevChapter.title,
      })

      handleSelectChapter(prevChapter.id)
    }
  }

  const handleSelectChapter = (chapterId: string) => {
    dispatch(selectChapter(chapterId))
  }

  const handlePlay = (trackProgress: number) => {
    if (trackProgress === 0) {
      handleTrackStart()
      return
    }
    mixpanel.track('Audio: Continue track', {
      chapterId: selectedChapter?.id,
      chapterTitle: selectedChapter?.title,
      trackProgress: Math.floor(trackProgress),
    })
  }

  const handlePause = (trackProgress: number) => {
    mixpanel.track('Audio: Pause track', {
      chapterId: selectedChapter?.id,
      chapterTitle: selectedChapter?.title,
      trackProgress: Math.floor(trackProgress),
    })
  }

  const handleUpdateProgress = (progress: number) => {
    if (selectedChapter) {
      progressContainer.current[selectedChapter.id] = Math.floor(progress)
    }
  }

  const handleTrackStart = () => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(intervalCallbackFunction, PROGRESS_UPDATE_IN_SECONDS * 1000)
    }

    mixpanel.track('Audio: Start track', {
      chapterId: selectedChapter?.id,
      chapterTitle: selectedChapter?.title,
    })
  }

  const intervalCallbackFunction = () => {
    Object.keys(progressContainer.current).forEach(chapterId => {
      const chapterTitle = chapters.find(value => value.id === chapterId)?.title
      const trackProgress = progressContainer.current[chapterId]
      if (trackProgress) {
        mixpanel.track('Audio: Progress', {
          chapterTitle,
          chapterId,
          trackProgress,
        })
      }
    })
  }

  const handleTimeSkip = (trackProgress: number) => {
    mixpanel.track('Audio: Skip track time', {
      chapterId: selectedChapter?.id,
      chapterTitle: selectedChapter?.title,
      trackProgress: Math.floor(trackProgress),
    })
  }

  const tags = selectedChapter?.tags
    ? selectedChapter.tags.reduceRight((acc: string[], val) => {
        acc.push(val)
        return acc
      }, [])
    : []

  return (
    <TourPageContainer>
      <Typography type="header">{selectedChapter?.title || ''}</Typography>
      {selectedChapter && (
        <>
          <ImageContainer>
            <ImageComponent source={selectedChapter.coverUrl} alt={selectedChapter.audioTitle} />
          </ImageContainer>
          {selectedChapter.tags && (
            <ChipContainer>
              {tags.map((tag, index) => (
                <Chip isBlue variant={index % 2 ? 'outlined' : 'filled'} key={index} label={tag} />
              ))}
            </ChipContainer>
          )}
          {selectedChapter.description && (
            <DescriptionContainer>
              <Typography type="info">{selectedChapter.description}</Typography>
            </DescriptionContainer>
          )}
          <AudioContainer>
            <AudioPlayer
              onNext={handleNextChapterClick}
              onPrev={handlePrevChapterClick}
              disablePrev={!selectedChapter.order}
              currentSong={selectedChapter.audioUrl}
              onPlay={handlePlay}
              onPause={handlePause}
              onTrackStart={handleTrackStart}
              onTimeSkip={handleTimeSkip}
              setIsTrackReady={handleTrackIsReady}
              updateProgress={handleUpdateProgress}
              isTrackReady={isTrackReady}
            />
          </AudioContainer>
          {!isTrackReady && <Loader onFinish={onLoaderFinish} />}
        </>
      )}
    </TourPageContainer>
  )
}
