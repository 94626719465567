import React from 'react'
import ReactDOM from 'react-dom/client'
import './css/index.scss'
import App from './App'
import { store } from './store'
import { Provider } from 'react-redux'

const registerServiceWorker = async () => {
  console.log('start SW reg')
  if ('serviceWorker' in navigator) {
    try {
      await navigator.serviceWorker
        .register('service-worker.js', {
          scope: './',
        })
        .then(registration => {
          console.log('Service worker installing', registration)
        })
        .catch(error => {
          console.error(`Registration failed with ${error}`)
        })
    } catch (error) {
      console.error(`Registration failed with ${error}`)
    }
  }
}

registerServiceWorker()
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)
