import styled from 'styled-components'
import { Button } from '@mui/material'
import { ButtonVariantEnum } from '.'

export const StyledButton = styled(Button)`
  &.MuiButton-root,
  .MuiButtonBase-root {
    color: ${props =>
      props.variant === ButtonVariantEnum.contained ? '#fff' : '#346EEB'} !important;
    font-family: Poppins !important;
    font-size: 14px !important;
    padding: 16px 10px;
    border-radius: 8px;
    text-transform: capitalize;
    background-color: ${props =>
      props.variant === ButtonVariantEnum.contained ? '#346EEB' : '#fff'} !important;
    max-width: 148px !important;
    width: 100%;
    max-height: 55px;
    height: 100%;
  }
  .MuiButton-startIcon {
    margin-top: -2px;
  }
`
