import styled from 'styled-components'

export const TourPageContainer = styled.div`
  margin: 40px 26px 30px 26px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

export const ImageContainer = styled.div`
  margin: 10px 0 20px 0;
`

export const AudioContainer = styled.div`
  margin-top: 50px;
  position: fixed;
  width: 90vw;
  max-width: 480px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
`
export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 12px;
  padding-bottom: 150px;
  overflow: auto;
`

export const ChipContainer = styled.div`
  margin-top: 18px;
  display: flex;
  gap: 8px;
`
export const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 100;
`
