import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface PlayerState {
  autoplay: boolean
}

const initialState: PlayerState = {
  autoplay: false,
}

export const playerSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAutoplay: (state, action: PayloadAction<{ status: boolean }>) => {
      state.autoplay = action.payload.status
    },
  },
})

export const { setAutoplay } = playerSlice.actions

export default playerSlice.reducer
