import { configureStore } from '@reduxjs/toolkit'
import baseReducer from './redux/baseSlice'
import loadingSlice from './redux/loadingSlice'
import chaptersSlice from './redux/chaptersSlice'
import tourSlice from './redux/tourSlice'
import userSlice from './redux/userSlice'
import playerSlice from './redux/playerSlice'

export const store = configureStore({
  reducer: {
    base: baseReducer,
    loading: loadingSlice,
    chapters: chaptersSlice,
    tour: tourSlice,
    user: userSlice,
    player: playerSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
