import React, { useEffect } from 'react'
import { CircularProgress } from '@mui/material'
import styled from 'styled-components'

const LoaderContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(180, 180, 180, 0.4);
`

type LoaderProps = {
  onFinish?: (time: number) => void
}

const Loader: React.FC<LoaderProps> = ({ onFinish }) => {
  useEffect(() => {
    const startTime = Date.now()
    return () => {
      const finishTime = Math.floor((Date.now() - startTime) / 1000)
      onFinish && onFinish(finishTime)
    }
  })

  return (
    <LoaderContainer>
      <CircularProgress />
    </LoaderContainer>
  )
}

export default Loader
