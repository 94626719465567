import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const baseSlice = createSlice({
  name: 'base',
  initialState,
  reducers: {},
})

export default baseSlice.reducer
