import React, { useEffect } from 'react'
import { ChaptersContainer } from './style'
import { Chapter } from '../../components/Chapter'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useNavigate } from 'react-router-dom'
import { selectChapter } from '../../redux/chaptersSlice'
import { setAutoplay } from '../../redux/playerSlice'

interface IChapterProps {}

export const ChaptersPage: React.FC<IChapterProps> = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const chapters = useSelector((state: RootState) => state.chapters.chapters)

  useEffect(() => {
    if (!chapters || !chapters.length) {
      navigate('/')
    }
  }, [chapters, navigate])

  const handleChapterClick = (chapterId: string) => {
    dispatch(selectChapter(chapterId))
    dispatch(setAutoplay({ status: true }))
    navigate('/player')
  }

  return (
    <ChaptersContainer>
      {chapters.map(chapter => (
        <Chapter onClick={handleChapterClick} key={chapter.id} chapter={chapter} />
      ))}
    </ChaptersContainer>
  )
}
