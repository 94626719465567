import React, { ReactElement } from 'react'
import { StyledTypography } from './style'

export enum TypographyEnum {
  header = 'header',
  default = 'default',
  info = 'info',
}

export type TypographyType = keyof typeof TypographyEnum

type TypographyProps = {
  children: string | string[] | ReactElement | ReactElement[]
  type?: TypographyType
  className?: string
  style?: React.CSSProperties
}

export function Typography({ children, type, className, style }: TypographyProps) {
  return (
    <StyledTypography $type={type} className={className} style={style}>
      {children}
    </StyledTypography>
  )
}
