import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Chapter } from '../utils/types'

export interface ChapterState {
  chapters: Chapter[]
  selectedChapter?: Chapter
}

const initialState: ChapterState = {
  chapters: [],
  selectedChapter: undefined,
}

export const loadingSlice = createSlice({
  name: 'chapter',
  initialState,
  reducers: {
    setChapters: (state, action: PayloadAction<Chapter[]>) => {
      state.chapters = action.payload
    },
    selectChapter: (state, action: PayloadAction<string>) => {
      const newSelectedChapter = state.chapters.find(chapter => chapter.id === action.payload)
      state.selectedChapter = newSelectedChapter
    },
  },
})

export const { setChapters, selectChapter } = loadingSlice.actions

export default loadingSlice.reducer
