import React from 'react'
import { FeedbackContainer } from './style'
import { CryingEmojiIcon } from '../../icons/emotions/CryingEmojiIcon'
import { SadEmojiIcon } from '../../icons/emotions/SadEmojiIcon'
import { NeutralEmojiIcon } from '../../icons/emotions/NeutralEmojiIcon'
import { HappyEmojiIcon } from '../../icons/emotions/HappyEmojiIcon'
import { InLoveEmojiIcon } from '../../icons/emotions/InLoveEmojiIcon'

interface FeedbackScoreProps {
  selectedScore: number | null
  setSelectedScore: (n: number | null) => void
}

const EMOJI_WITH_VALUE = [
  {
    icon: <CryingEmojiIcon />,
    value: 1,
  },
  {
    icon: <SadEmojiIcon />,
    value: 2,
  },
  {
    icon: <NeutralEmojiIcon />,
    value: 3,
  },
  { icon: <HappyEmojiIcon />, value: 4 },
  { icon: <InLoveEmojiIcon />, value: 5 },
]

export const FeedbackScore: React.FC<FeedbackScoreProps> = ({
  selectedScore,
  setSelectedScore,
}) => {
  return (
    <FeedbackContainer>
      {EMOJI_WITH_VALUE.map(emoji => (
        <span
          onClick={() => setSelectedScore(emoji.value)}
          style={{
            filter:
              selectedScore !== null && emoji.value !== selectedScore ? 'grayscale(1)' : 'none',
          }}
          key={emoji.value}
        >
          {emoji.icon}
        </span>
      ))}
    </FeedbackContainer>
  )
}
