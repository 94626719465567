import React from 'react'

export const SadEmojiIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
      <path
        d="M36.75 18C36.75 27.941 28.691 36 18.75 36C8.81 36 0.75 27.941 0.75 18C0.75 8.06 8.81 0 18.75 0C28.691 0 36.75 8.06 36.75 18Z"
        fill="#FFCC4D"
      />
      <path
        d="M12.25 18C13.6307 18 14.75 16.433 14.75 14.5C14.75 12.567 13.6307 11 12.25 11C10.8693 11 9.75 12.567 9.75 14.5C9.75 16.433 10.8693 18 12.25 18Z"
        fill="#664500"
      />
      <path
        d="M25.25 18C26.6307 18 27.75 16.433 27.75 14.5C27.75 12.567 26.6307 11 25.25 11C23.8693 11 22.75 12.567 22.75 14.5C22.75 16.433 23.8693 18 25.25 18Z"
        fill="#664500"
      />
      <path
        d="M9.41511 27.871C9.59311 28.032 9.85911 28.042 10.0501 27.9C10.0891 27.871 13.9721 25 18.7501 25C23.5161 25 27.4121 27.871 27.4501 27.9C27.6411 28.042 27.9071 28.03 28.0851 27.871C28.2621 27.711 28.3021 27.447 28.1791 27.243C28.0501 27.029 24.9621 22 18.7501 22C12.5381 22 9.44911 27.028 9.32111 27.243C9.19811 27.448 9.23711 27.711 9.41511 27.871Z"
        fill="#664500"
      />
    </svg>
  )
}
