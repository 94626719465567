import React from 'react'
import { IconProps } from '../utils/types'

const PlayIcon: React.FC<IconProps> = ({ width = 61, height = 61, fill = '#346EEB' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 61 60" fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 30.0174C0.5 13.4588 13.9653 0 30.5 0C47.0347 0 60.5 13.4588 60.5 30.0174C60.5 46.5412 47.0347 60 30.5 60C13.9653 60 0.5 46.5412 0.5 30.0174ZM41.5039 33.0535C41.8224 32.7352 42.2278 32.2431 42.3147 32.1273C42.778 31.5195 43.0097 30.767 43.0097 30.0174C43.0097 29.1751 42.749 28.3936 42.2568 27.7569C42.2164 27.7165 42.1374 27.6306 42.0345 27.5185C41.8418 27.3089 41.5654 27.0079 41.3012 26.7438C38.9266 24.1968 32.7297 20.0289 29.4865 18.7554C28.9942 18.5557 27.749 18.1187 27.083 18.0897C26.4459 18.0897 25.8378 18.2344 25.2587 18.5239C24.5348 18.9291 23.9556 19.5658 23.6371 20.3184C23.4344 20.8394 23.1158 22.4023 23.1158 22.4313C22.7973 24.1389 22.6236 26.9175 22.6236 29.9855C22.6236 32.9117 22.7973 35.5716 23.0579 37.3082C23.0651 37.3154 23.09 37.4396 23.1287 37.6326C23.2463 38.2192 23.4914 39.4412 23.7529 39.9421C24.39 41.1577 25.6351 41.9103 26.9672 41.9103H27.083C27.9517 41.8813 29.7761 41.1288 29.7761 41.0999C32.8456 39.8263 38.8977 35.8611 41.3301 33.2272L41.5039 33.0535Z"
        fill={fill}
      />
    </svg>
  )
}

export default PlayIcon
