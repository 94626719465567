import React from 'react'
import { IconProps } from '../utils/types'

export const PlayTourIcon: React.FC<IconProps> = ({ width = 17, height = 17, fill = '#fff' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 17" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49996 0.166748C3.89759 0.166748 0.166626 3.89772 0.166626 8.50008C0.166626 13.1024 3.89759 16.8334 8.49996 16.8334C13.1023 16.8334 16.8333 13.1024 16.8333 8.50008C16.8333 3.89772 13.1023 0.166748 8.49996 0.166748ZM8.49996 1.32954C12.4601 1.32954 15.6705 4.53991 15.6705 8.50008C15.6705 12.4603 12.4601 15.6706 8.49996 15.6706C4.53979 15.6706 1.32942 12.4603 1.32942 8.50008C1.32942 4.53991 4.53979 1.32954 8.49996 1.32954ZM7.5595 5.2122L7.47279 5.20396C7.30858 5.19832 7.13918 5.23954 6.98985 5.3201C6.79656 5.42358 6.64053 5.59126 6.55403 5.79887C6.51426 5.89498 6.4455 6.19166 6.41457 6.36396C6.33691 6.78741 6.29174 7.43863 6.28409 8.15329L6.28294 8.39363C6.28212 9.15187 6.32398 9.87361 6.40134 10.3492L6.44376 10.5676C6.48598 10.7619 6.54208 10.9689 6.58703 11.0562C6.75852 11.3875 7.10738 11.5921 7.47814 11.5803C7.63626 11.5768 7.96844 11.4664 8.19944 11.3694C9.03133 11.0195 10.4964 10.0387 11.1733 9.3774L11.3981 9.14344C11.4841 9.05151 11.5228 9.00873 11.5557 8.96397C11.6781 8.79759 11.7419 8.59657 11.7419 8.39303C11.7419 8.17679 11.6706 7.96565 11.5348 7.7934L11.4683 7.7166L11.282 7.51768C10.6428 6.83645 8.97034 5.71329 8.11599 5.38133C7.92808 5.30707 7.71617 5.23591 7.5595 5.2122ZM7.5883 6.42644L7.69176 6.46397C8.34714 6.71862 9.73781 7.64161 10.3311 8.20967L10.5077 8.39155L10.4408 8.46375C9.94603 8.99717 8.48508 9.98776 7.74897 10.2974L7.58907 10.3559L7.54868 10.1602C7.49072 9.80392 7.45385 9.23983 7.44691 8.62591L7.44572 8.39365C7.44504 7.72406 7.47924 7.09924 7.53814 6.6971L7.56644 6.53139L7.5883 6.42644Z"
        fill={fill}
      />
    </svg>
  )
}
