import { createHashRouter } from 'react-router-dom'
import { ChaptersPage } from './pages/Chapters'
import { TourPlayer } from './pages/TourPlayer'
import { Feedback } from './pages/Feedback'
import { TourPage } from './pages/TourPage'

export const router = createHashRouter([
  {
    path: '',
    element: <TourPage />,
  },
  {
    path: 'player',
    element: <TourPlayer />,
  },
  {
    path: 'overview',
    element: <ChaptersPage />,
  },
  {
    path: 'feedback',
    element: <Feedback />,
  },
])
