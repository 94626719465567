import React from 'react'

export const DocumentIcon = ({ width = 16, height = 17, fill = '#346EEB' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 17" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.78142 16.8334C4.43809 16.8334 4.11226 16.8067 3.80559 16.7542L3.62392 16.7201C1.64809 16.3092 0.499756 14.7959 0.499756 12.4926V4.50758C0.499756 4.22925 0.516423 3.96258 0.549756 3.70758C0.840589 1.47008 2.37809 0.166748 4.78142 0.166748H11.2189C13.8823 0.166748 15.4748 1.76675 15.4998 4.44341V4.50758V12.4926C15.4998 15.2067 13.9031 16.8334 11.2189 16.8334H4.78142ZM4.78142 1.38091C2.73476 1.38091 1.69726 2.43341 1.69726 4.50758V12.4926C1.69726 14.5667 2.73476 15.6192 4.78142 15.6192H11.2114C13.2573 15.6192 14.2881 14.5667 14.2881 12.4926V4.50758C14.2881 2.43341 13.2573 1.38091 11.2114 1.38091H4.78142ZM11.5881 12.0284C11.5881 11.6951 11.3223 11.4251 10.9939 11.4251H4.98476C4.65642 11.4251 4.38976 11.6951 4.38976 12.0284C4.38976 12.3617 4.65642 12.6317 4.98476 12.6317H10.9939C11.3223 12.6317 11.5881 12.3617 11.5881 12.0284ZM10.9939 7.89258C11.2231 7.86758 11.4473 7.97758 11.5698 8.17508C11.6923 8.37425 11.6923 8.62591 11.5698 8.82508C11.4473 9.02258 11.2231 9.13258 10.9939 9.10758H4.98476C4.67976 9.07258 4.44892 8.81175 4.44892 8.50008C4.44892 8.18841 4.67976 7.92675 4.98476 7.89258H10.9939ZM7.81392 4.97591C7.81392 4.66425 7.58309 4.40258 7.27809 4.36841H4.99226C4.68642 4.40258 4.45559 4.66425 4.45559 4.97591C4.45559 5.28675 4.68642 5.54841 4.99226 5.58258H7.27809C7.58309 5.54841 7.81392 5.28675 7.81392 4.97591Z"
        fill={fill}
      />
    </svg>
  )
}
