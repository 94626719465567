import React from 'react'
import { Slider } from '@mui/material'

interface AudioProgressBarProps {
  duration: number
  currentProgress: number
  onChange: (e: any) => void
  bufferedPercentage?: number
}

export const AudioProgressBar: React.FC<AudioProgressBarProps> = ({
  duration,
  currentProgress,
  onChange,
  bufferedPercentage,
}) => {
  return (
    <Slider
      valueLabelDisplay="off"
      min={0}
      max={duration}
      value={currentProgress}
      onChange={onChange}
      name="progress"
      size="small"
      sx={{
        '& .MuiSlider-rail': {
          backgroundColor: '#BBB1FA',
          '&::after': {
            content: '""',
            background: '#1976d2',
            width: `${bufferedPercentage || 0}%`,
            height: '100%',
            display: 'block',
          },
        },
        '& .MuiSlider-track': {
          backgroundColor: '#346EEB',
        },
        '& .MuiSlider-thumb': {
          width: 8,
          height: 8,
          backgroundColor: '#346EEB',
        },
      }}
    />
  )
}
