import React from 'react'
import { IconProps } from '../utils/types'

const PlayBackIcon: React.FC<IconProps> = ({ width = 34, height = 34, fill = '#2E2E5D' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Arrow - Left Circle">
        <path
          id="Arrow - Left Circle_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.6667 17.0004C33.6667 7.79561 26.2043 0.33374 17.0001 0.33374C7.79584 0.33374 0.333412 7.79561 0.333412 17.0004C0.333412 26.2041 7.79639 33.6671 17.0001 33.6671C26.2038 33.6671 33.6667 26.2041 33.6667 17.0004ZM31.1667 17.0004C31.1667 24.8234 24.8231 31.1671 17.0001 31.1671C9.1771 31.1671 2.83341 24.8234 2.83341 17.0004C2.83341 9.17636 9.17651 2.83374 17.0001 2.83374C24.8237 2.83374 31.1667 9.17636 31.1667 17.0004ZM20.4072 11.9609C20.7714 11.4724 20.7325 10.7778 20.2897 10.3331C19.8026 9.84389 19.0111 9.84219 18.5219 10.3293L12.7119 16.1143L12.5903 16.2547C12.2253 16.7448 12.2658 17.4417 12.7119 17.8859L18.5219 23.6709L18.6624 23.7916C19.1525 24.1536 19.8469 24.1118 20.2897 23.667L20.4104 23.5266C20.7725 23.0365 20.7306 22.3421 20.2859 21.8993L15.3667 16.9984L20.2859 12.1009L20.4072 11.9609Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default PlayBackIcon
