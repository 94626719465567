import React from 'react'

export const NeutralEmojiIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
      <path
        d="M36.5 18C36.5 27.941 28.441 36 18.5 36C8.56 36 0.5 27.941 0.5 18C0.5 8.06 8.56 0 18.5 0C28.441 0 36.5 8.06 36.5 18Z"
        fill="#FFCC4D"
      />
      <path
        d="M12 20C13.3807 20 14.5 18.433 14.5 16.5C14.5 14.567 13.3807 13 12 13C10.6193 13 9.5 14.567 9.5 16.5C9.5 18.433 10.6193 20 12 20Z"
        fill="#664500"
      />
      <path
        d="M25 20C26.3807 20 27.5 18.433 27.5 16.5C27.5 14.567 26.3807 13 25 13C23.6193 13 22.5 14.567 22.5 16.5C22.5 18.433 23.6193 20 25 20Z"
        fill="#664500"
      />
      <path
        d="M25.5 26H11.5C10.948 26 10.5 25.553 10.5 25C10.5 24.447 10.948 24 11.5 24H25.5C26.053 24 26.5 24.447 26.5 25C26.5 25.553 26.053 26 25.5 26Z"
        fill="#664500"
      />
    </svg>
  )
}
