import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { RouterProvider } from 'react-router-dom'
import { LoadingComponent } from './LoadingScreen'
import { RootState } from './store'
import { router } from './Routes'
import { useMixpanel } from './mixpanel'
import { setTour } from './redux/tourSlice'
import { setChapters } from './redux/chaptersSlice'

const MainContainer = styled.div`
  margin: 20px auto;
  max-width: 480px;
`

function App() {
  const isLoadingComplete = useSelector((state: RootState) => state.loading.loading)
  const tour = useSelector((state: RootState) => state.tour.tour)
  useMixpanel()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setTour())
  }, [dispatch])

  useEffect(() => {
    dispatch(setChapters(tour.chapters))
  }, [tour, dispatch])

  return (
    <MainContainer>
      {isLoadingComplete ? <LoadingComponent /> : <RouterProvider router={router} />}
    </MainContainer>
  )
}

export default App
