import React, { useCallback, useEffect, useMemo } from 'react'
import debounce from 'lodash/debounce'
import { AudioProgressBar } from './AudioProgressBar'
import { formatDurationDisplay } from '../../utils/utils'
import {
  Container,
  ControlButton,
  ControlButtonsContainer,
  ControlContainer,
  ProgressBarContainer,
  ProgressTime,
  StopButton,
} from './style'
import PlayBackIcon from '../../icons/PlayBackIcon'
import PlayIcon from '../../icons/PlayIcon'
import PlayNextIcon from '../../icons/PlayNextIcon'
import PauseIcon from '../../icons/PauseIcon'
import { useDispatch, useSelector } from 'react-redux'
import { DocumentIcon } from '../../icons/DocumentIcon'
import { useNavigate } from 'react-router-dom'
import { setIsUserListenedTour } from '../../redux/userSlice'
import { ForwardIcon } from '../../icons/ForwardIcon'
import { RootState } from '../../store'
import { setAutoplay } from '../../redux/playerSlice'

interface AudioPlayerProps {
  currentSong?: any
  onNext?: (duration: number, currentProgress: number) => void
  onPrev?: () => void
  onPause?: (progress: number) => void
  onPlay?: (progress: number) => void
  onTrackStart?: () => void
  onTimeSkip: (progress: number) => void
  disableNext?: boolean
  disablePrev?: boolean
  setIsTrackReady?: (s: boolean) => void
  updateProgress: (progress: number) => void
  isTrackReady?: boolean
}

export const AudioPlayer: React.FC<AudioPlayerProps> = ({
  currentSong,
  onNext,
  onPrev,
  disablePrev,
  disableNext,
  onPause,
  onPlay,
  onTrackStart,
  onTimeSkip,
  setIsTrackReady,
  updateProgress,
  isTrackReady = true,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { autoplay } = useSelector((state: RootState) => state.player)

  const audioRef = React.useRef<HTMLAudioElement | null>(null)

  const [duration, setDuration] = React.useState(0)
  const [currentProgress, setCurrentProgress] = React.useState(0)
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [buffered, setBuffered] = React.useState(0)

  const durationDisplay = formatDurationDisplay(duration)
  const elapsedDisplay = formatDurationDisplay(currentProgress)

  const resetTime = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    setCurrentProgress(0)
    if (audioRef.current) {
      audioRef.current.currentTime = 0
    }
  }

  const handleNext = () => {
    onNext && onNext(duration, currentProgress)
    isPlaying && dispatch(setAutoplay({ status: true }))
    resetTime()
  }

  const handleEnd = () => {
    onNext && onNext(duration, currentProgress)
    dispatch(setAutoplay({ status: false }))
    resetTime()
  }

  const handlePrev = () => {
    onPrev && onPrev()
    isPlaying && dispatch(setAutoplay({ status: true }))
    resetTime()
  }

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current?.pause()
      setIsPlaying(false)
      onPause && onPause(currentProgress)
    } else {
      audioRef.current?.play()
      setIsPlaying(true)
      onPlay && onPlay(currentProgress)
    }
  }

  const handleDocumentClick = () => {
    navigate('/overview')
  }

  const handlePlaying = useCallback(() => {
    setIsPlaying(true)
    dispatch(setIsUserListenedTour())
  }, [dispatch, setIsPlaying])

  const debounceOnTimeSkip = useMemo(
    () =>
      debounce((value: number) => {
        onTimeSkip(value)
      }, 3000),
    [onTimeSkip]
  )

  const handleAudioTime = useCallback(
    (value: number) => {
      if (!audioRef.current) return

      audioRef.current.currentTime = value
      setCurrentProgress(value)
      debounceOnTimeSkip(value)
    },
    [debounceOnTimeSkip]
  )

  const handleSkipTime = useCallback(
    (value: number) => {
      handleAudioTime(currentProgress + value)
    },
    [currentProgress, handleAudioTime]
  )

  const handleBufferProgress: React.ReactEventHandler<HTMLAudioElement> = e => {
    const audio = e.currentTarget
    const dur = audio.duration
    if (dur > 0 && audio.buffered.length) {
      for (let i = 0; i < audio.buffered.length; i++) {
        if (audio.buffered.start(audio.buffered.length - 1 - i) < audio.currentTime) {
          const bufferedLength = audio.buffered.end(audio.buffered.length - 1 - i)
          setBuffered(bufferedLength)

          break
        }
      }
    }
  }

  const loadedPercentage = useMemo(() => {
    if (!audioRef.current) return 0
    const dur = audioRef.current?.duration

    return (buffered * 100) / dur
  }, [buffered])

  useEffect(() => {
    if (currentSong && autoplay && isTrackReady) {
      audioRef.current?.play()
      setIsPlaying(false)
      onTrackStart && onTrackStart()
      dispatch(setAutoplay({ status: false }))
    }
  }, [currentSong, onTrackStart, autoplay, dispatch, isTrackReady])

  useEffect(() => {
    if (currentSong && navigator.userAgent.toUpperCase().includes('SAFARI') && !isPlaying) {
      audioRef.current?.load()
    }
  }, [currentSong])

  if (!currentSong) return null

  return (
    <Container>
      <audio
        ref={audioRef}
        preload="metadata"
        onDurationChange={e => setDuration(e.currentTarget.duration)}
        onPlaying={handlePlaying}
        onPause={() => setIsPlaying(false)}
        onEnded={handleEnd}
        onTimeUpdate={e => {
          updateProgress(e.currentTarget.currentTime)
          setCurrentProgress(e.currentTarget.currentTime)
          handleBufferProgress(e)
        }}
        src={currentSong}
        onCanPlayThrough={() => {
          setIsTrackReady && setIsTrackReady(true)
        }}
        onProgress={handleBufferProgress}
        onWaiting={() => {
          setIsTrackReady && setIsTrackReady(false)
        }}
      />
      <ProgressBarContainer>
        <AudioProgressBar
          duration={duration}
          currentProgress={currentProgress}
          onChange={e => {
            handleAudioTime(e.target.value)
          }}
          bufferedPercentage={loadedPercentage}
        />
        <ProgressTime>
          <span>{elapsedDisplay}</span>
          <span>{durationDisplay}</span>
        </ProgressTime>
      </ProgressBarContainer>

      <ControlContainer>
        <ControlButton onClick={handleDocumentClick}>
          <DocumentIcon width={23} height={25} fill={'#2E2E5D'} />
        </ControlButton>
        <ControlButtonsContainer>
          <ControlButton onClick={handlePrev} aria-label="go to previous" disabled={disablePrev}>
            <PlayBackIcon fill={disablePrev ? 'grey' : undefined} />
          </ControlButton>
          <ControlButton
            disabled={!currentSong}
            onClick={togglePlayPause}
            aria-label={isPlaying ? 'Pause' : 'Play'}
            maxHeight={63}
          >
            {isPlaying ? (
              <StopButton>
                <PauseIcon width={45} height={45} />
              </StopButton>
            ) : (
              <StopButton $background="#fff">
                <PlayIcon />
              </StopButton>
            )}
          </ControlButton>

          <ControlButton onClick={handleNext} aria-label="go to next" disabled={disableNext}>
            <PlayNextIcon fill={disableNext ? 'grey' : undefined} />
          </ControlButton>
        </ControlButtonsContainer>
        <ControlButton
          onClick={() => {
            handleSkipTime(30)
          }}
        >
          <ForwardIcon />
        </ControlButton>
      </ControlContainer>
    </Container>
  )
}
