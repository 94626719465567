import React, { useEffect } from 'react'
import { CircularProgress } from '@mui/material'
import { useDispatch } from 'react-redux'
import { complete } from '../redux/loadingSlice'
import { LoaderContainer, LoadingContainer, LoadingImageContainer } from './style'

export function LoadingComponent() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(complete())
  }, [dispatch])

  return (
    <LoadingContainer>
      <LoadingImageContainer className="container">
        <img src="/wisit_logo.png" alt="Wisit" />
        <img src="/museum_logo.png" alt="Lietuvos nacionalinis muziejus" />
      </LoadingImageContainer>
      <LoaderContainer className="loader">
        <CircularProgress />
      </LoaderContainer>
    </LoadingContainer>
  )
}
