import React from 'react'

export const CryingEmojiIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <g clipPath="url(#clip0_191_646)">
        <path
          d="M36 18C36 27.941 27.941 36 18 36C8.06 36 0 27.941 0 18C0 8.06 8.06 0 18 0C27.941 0 36 8.06 36 18Z"
          fill="#FFCC4D"
        />
        <path
          d="M11.5 20C12.8807 20 14 18.433 14 16.5C14 14.567 12.8807 13 11.5 13C10.1193 13 9 14.567 9 16.5C9 18.433 10.1193 20 11.5 20Z"
          fill="#664500"
        />
        <path
          d="M24.5 20C25.8807 20 27 18.433 27 16.5C27 14.567 25.8807 13 24.5 13C23.1193 13 22 14.567 22 16.5C22 18.433 23.1193 20 24.5 20Z"
          fill="#664500"
        />
        <path
          d="M23.4851 27.879C23.4741 27.835 22.3401 23.5 18.0001 23.5C13.6601 23.5 12.5261 27.835 12.5151 27.879C12.4621 28.092 12.5591 28.31 12.7471 28.423C12.9351 28.535 13.1801 28.509 13.3431 28.363C13.3521 28.356 14.3561 27.5 18.0001 27.5C21.5901 27.5 22.6171 28.33 22.6561 28.363C22.7511 28.454 22.8751 28.5 23.0001 28.5C23.0841 28.5 23.1691 28.479 23.2461 28.436C23.4421 28.325 23.5401 28.097 23.4851 27.879Z"
          fill="#664500"
        />
        <path
          d="M10 30C10 32.762 7.762 35 5 35C2.238 35 0 32.762 0 30C0 27.238 4 20 5 20C6 20 10 27.238 10 30Z"
          fill="#5DADEC"
        />
        <path
          d="M30 12.9999C24.446 12.9999 22.198 8.6329 22.105 8.4469C21.858 7.9529 22.058 7.3519 22.552 7.1049C23.044 6.8579 23.644 7.0569 23.892 7.5479C23.967 7.6939 25.713 10.9999 30 10.9999C30.553 10.9999 31 11.4479 31 11.9999C31 12.5529 30.553 12.9999 30 12.9999ZM6 12.9999C5.448 12.9999 5 12.5519 5 11.9999C5 11.4479 5.448 10.9999 6 10.9999C11.083 10.9999 11.996 7.8799 12.033 7.7469C12.178 7.2189 12.723 6.8989 13.252 7.0379C13.782 7.1769 14.103 7.7109 13.97 8.2429C13.921 8.4369 12.704 12.9999 6 12.9999Z"
          fill="#664500"
        />
      </g>
      <defs>
        <clipPath id="clip0_191_646">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
