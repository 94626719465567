import React, { ReactElement, ReactNode } from 'react'
import { StyledButton } from './style'

export enum ButtonVariantEnum {
  outlined = 'outlined',
  contained = 'contained',
}

export type ButtonType = keyof typeof ButtonVariantEnum

interface ButtonProps {
  type?: ButtonType
  onClick?: () => void
  icon?: ReactNode
  children?: ReactElement | string
}

export const Button: React.FC<ButtonProps> = ({
  type = ButtonVariantEnum.contained,
  onClick,
  icon,
  children,
}) => {
  return (
    <StyledButton startIcon={icon} variant={type} onClick={onClick}>
      {children}
    </StyledButton>
  )
}
