import styled from 'styled-components'

export const FormContainer = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'isOpen',
})<{ isOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ isOpen }) => (isOpen ? 'flex-start' : 'center')};
  align-items: center;
  padding: 5px;
  height: 90vh;

  p {
    display: flex;
    align-self: flex-start;
    margin-left: 20px;
    ${({ isOpen }) => (isOpen ? 'margin-bottom: 10px;' : '')}
  }
`
export const FeedbackHeader = styled.span`
  color: #18181b;
  font-size: 20px;
  font-weight: 900;
  line-height: 25px;
  letter-spacing: -0.4px;
`

export const FeedbackText = styled.p`
  color: #52525b;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.4px;
`
export const FeedbackTextContainer = styled.div`
  margin: 32px auto;
  text-align: center;
`

export const FeedbackContainer = styled.div`
  display: flex;
  margin: 25px;
  justify-content: space-around;
  width: 100%;
`

export const FeedbackMessage = styled.textarea`
  font-family: Poppins, sans-serif;
  max-width: 340px;
  width: 100%;
  height: 120px;
  margin: 20px 0 0 0;
  padding: 10px;
  resize: none;
`

export const ButtonContainer = styled.div`
  width: 100%;
  max-width: 480px;
  position: fixed;
  bottom: 10px;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  button.MuiButton-root.MuiButtonBase-root {
    width: calc(100% - 40px) !important;
    max-width: none !important;
  }
`

export const FeedbackScoreContainer = styled.div`
  p {
    margin-bottom: 20px;
  }
`
