import styled from 'styled-components'
import { Chip } from '@mui/material'

const getTextColor = (color: boolean = false, type: string = '') => {
    if (color) {
        if (type === 'filled'){
            return '#fff'
        }
        return '#346eeb'
    }
    return '#6a6a8b'
}

export const StyledChip = styled(Chip)<{$color?: boolean}>`
  &.MuiChip-root {
    ${props => props.$color ? "border: 1px solid #346eeb !important;" : "border: 1px solid #6a6a8b !important;"}
    color: ${props => props.$color ? '#346eeb' : '#6a6a8b'} !important;
    ${props => props.$color && props.variant === 'filled' && 'background-color: #346eeb !important;'}
  }
  .MuiChip-label {
    font-family: Poppins !important;
    color: ${props => getTextColor(props.$color, props.variant)}  !important;
    padding: 4px 12px !important;
    font-size: 12px !important;
    text-transform: capitalize;
  }
`
