import React from 'react'
import { Typography } from '../../components/Typography'
import { ImageComponent } from '../../components/Image'
import { Chip } from '../../components/Chip'
import { Button } from '../../components/Button'
import {
  ChipContainer,
  TourPageContainer,
  ImageContainer,
  ButtonContainer,
  SummaryContainer,
} from './style'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { selectChapter } from '../../redux/chaptersSlice'
import { DocumentIcon } from '../../icons/DocumentIcon'
import { PlayTourIcon } from '../../icons/PlayTourIcon'
import { setAutoplay } from '../../redux/playerSlice'

export function TourPage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const tour = useSelector((state: RootState) => state.tour.tour)

  const onPlayButtonClick = () => {
    navigate('/player')
    dispatch(selectChapter(tour.chapters[0].id))
    dispatch(setAutoplay({ status: true }))
  }

  const onOverviewButtonClick = () => {
    navigate('/overview')
  }

  return (
    <TourPageContainer>
      <Typography type="header">{tour.title}</Typography>
      <ImageContainer>
        <ImageComponent source={tour.coverUrl} alt={tour.coverTitle} />
      </ImageContainer>
      <Typography>{tour.coverTitle}</Typography>
      <Typography type="info">{`Narrated by: ${tour.narrator}`}</Typography>
      <ChipContainer>
        {tour.tags.map((tag, index) => (
          <Chip key={index} label={tag} />
        ))}
      </ChipContainer>

      <ButtonContainer>
        <Button
          onClick={onPlayButtonClick}
          icon={<PlayTourIcon width={21} height={21} fill={'#ffffff'} />}
          type="contained"
        >
          Play tour
        </Button>
        <Button
          onClick={onOverviewButtonClick}
          icon={<DocumentIcon width={21} height={21} fill={'#346EEB'} />}
          type="outlined"
        >
          Tour overview
        </Button>
      </ButtonContainer>
      <SummaryContainer>
        <Typography type="header">Summary</Typography>
        <Typography type="info">{tour.summary}</Typography>
      </SummaryContainer>
    </TourPageContainer>
  )
}
