import React from 'react'
import { StyledChip } from './style'

type ChipProps = {
  label: string
  isBlue?: boolean
  variant?: 'outlined' | 'filled'
}

export function Chip({ label, variant, isBlue }: ChipProps) {
  return <StyledChip $color={isBlue} variant={variant || 'outlined'} label={label} />
}
