import React from 'react'
import { IconProps } from '../utils/types'

const PlayNextIcon: React.FC<IconProps> = ({ width = 34, height = 34, fill = '#2E2E5D' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 34 34" fill="none">
      <g id="Arrow - Right Circle">
        <path
          id="Arrow - Right Circle_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.333252 16.9996C0.333252 26.2044 7.79568 33.6663 16.9999 33.6663C26.2042 33.6663 33.6666 26.2044 33.6666 16.9996C33.6666 7.7959 26.2036 0.332928 16.9999 0.332928C7.79623 0.332928 0.333252 7.7959 0.333252 16.9996ZM2.83325 16.9996C2.83325 9.17662 9.17694 2.83293 16.9999 2.83293C24.8229 2.83293 31.1666 9.17662 31.1666 16.9996C31.1666 24.8236 24.8235 31.1663 16.9999 31.1663C9.17635 31.1663 2.83325 24.8236 2.83325 16.9996ZM13.5928 22.0391C13.2286 22.5276 13.2675 23.2222 13.7103 23.6669C14.1974 24.1561 14.9889 24.1578 15.4781 23.6707L21.2881 17.8857L21.4097 17.7453C21.7747 17.2552 21.7341 16.5583 21.2881 16.1141L15.4781 10.3291L15.3376 10.2084C14.8475 9.84638 14.1531 9.88822 13.7103 10.333L13.5896 10.4734C13.2275 10.9635 13.2694 11.6579 13.7141 12.1007L18.6333 17.0016L13.7141 21.8991L13.5928 22.0391Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default PlayNextIcon
