import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  background-color: white;
`

export const ProgressBarContainer = styled.div`
  margin-bottom: 20px;
`

export const ProgressTime = styled.span`
  display: flex;
  justify-content: space-between;
  color: #bbb1fa;
  font-size: 12px;
  font-weight: 400;
`

export const ControlContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  transition: all 1s ease;
`

export const ControlButton = styled.button.withConfig({
  shouldForwardProp: prop => prop !== 'border' && prop !== 'background' && prop !== 'maxHeight',
})<{
  border?: string
  background?: string
  maxHeight?: number
}>`
  background: ${({ background }) => background || 'none'};
  border: ${({ border }) => border || 'none'};
  border-radius: 50%;
  -webkit-tap-highlight-color: transparent;
  position: relative;

  ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight}px;` : '')}

  transition: 0.8s all ease;

  &:active {
    opacity: 0.7;
  }
`

export const StopButton = styled.div<{ $background?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.$background || '#346eeb'};
  border-radius: 50%;
  width: 61px;
  height: 61px;
  max-height: 61px;
  max-width: 61px;
  padding: 0;
`

export const ControlButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1s ease;
`
