import React from 'react'
import { IconProps } from '../utils/types'

const PauseIcon: React.FC<IconProps> = () => {
  return (
    <svg width="31" height="31">
      <path
        fill={'#fff'}
        d="M11 7a3 3 0 0 1 3 3v12a3 3 0 0 1-6 0V10a3 3 0 0 1 3-3Zm10 0a3 3 0 0 1 3 3v12a3 3 0 0 1-6 0V10a3 3 0 0 1 3-3Zm0 0"
      />
    </svg>
  )
}

export default PauseIcon
