import styled from 'styled-components'

export const TourPageContainer = styled.div`
  margin: 40px 20px 30px 20px;
`

export const ImageContainer = styled.div`
  margin: 40px 0 20px 0;
`

export const ChipContainer = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  gap: 8px;
`

export const ButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 15px;
`

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 12px;
`
