import { createSlice } from '@reduxjs/toolkit'

interface UserState {
  isUserListenedTour: boolean
}

const initialState: UserState = {
  isUserListenedTour: false
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsUserListenedTour: state => {
      state.isUserListenedTour = true
    },
  },
})

export const { setIsUserListenedTour } = userSlice.actions

export default userSlice.reducer
