import { createSlice } from '@reduxjs/toolkit'
import { Tour } from '../utils/types'
import * as data from '../mockData.json'

export interface TourState {
  tour: Tour
}

const initialState: TourState = {
  tour: {
    id: '',
    title: '',
    coverUrl: '',
    coverTitle: '',
    narrator: '',
    summary: '',
    score: 0,
    tags: [],
    chapters: [],
  },
}

const Queue = () => {
  const items: Array<() => Promise<boolean>> = []

  const dequeue = () => {
    if (!items[0]) return

    items[0]()
      .then()
      .then(() => items.shift())
      .then(dequeue)
  }

  const enqueue = (promise: () => Promise<boolean>) => {
    items.push(promise)
    if (items.length === 1) dequeue()
  }

  return enqueue
}

const preloadAudioFiles = async (tour: Tour) => {
  const taskQueue = Queue()
  tour.chapters.forEach(({ audioUrl, coverUrl, iconUrl, title }) => {
    return taskQueue(() => {
      return new Promise(resolve => {
        const cover = new Image()
        const icon = new Image()
        const audio = new Audio()
        const checkIsLoaded = () => {
          const duration = audio.duration
          if (duration > 0 && audio.buffered.end.length && audio.buffered.end.length >= 1) {
            const bufferedLength = audio.buffered.end(audio.buffered.length - 1)
            if (bufferedLength === duration) {
              resolve(true)
              return
            } else {
              audio.currentTime = bufferedLength
            }
          }
          setTimeout(checkIsLoaded, 1000)
        }

        audio.preload = 'auto'
        icon.addEventListener('canplaythrough', () => {}, false)
        cover.addEventListener('canplaythrough', () => {}, false)
        audio.addEventListener('canplaythrough', () => {}, false)
        icon.src = iconUrl || coverUrl
        cover.src = coverUrl
        audio.src = audioUrl
        checkIsLoaded()
      })
    })
  })
}

export const tourSlice = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    setTour: state => {
      const convertedData: Tour = JSON.parse(JSON.stringify(data))
      preloadAudioFiles(data)

      state.tour = convertedData
    },
  },
})

export const { setTour } = tourSlice.actions

export default tourSlice.reducer
