import styled from 'styled-components'
import { Typography } from '@mui/material'
import { TypographyEnum, TypographyType } from '.'

function getColor(type: TypographyType = 'default'): string {
  switch (type) {
    case TypographyEnum.header:
      return '#2E2E5D'
    case TypographyEnum.default:
      return '#010104'
    case TypographyEnum.info:
      return '#9292A2'
    default:
      return '#ffffff'
  }
}

export const StyledTypography = styled(Typography)<{ $type?: TypographyType }>`
  font-family: Poppins, sans-serif !important;
  font-size: ${props => (props.$type === TypographyEnum.header ? 18 : 16)}px !important;
  color: ${props => getColor(props.$type)};
`
