import React from 'react'

export const InLoveEmojiIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <path
        d="M36 18C36 27.941 27.941 36 18 36C8.059 36 0 27.941 0 18C0 8.059 8.059 0 18 0C27.941 0 36 8.059 36 18Z"
        fill="#FFCC4D"
      />
      <path
        d="M17.9999 21.8491C15.0339 21.8491 13.0649 21.5031 10.6309 21.0301C10.0739 20.9241 8.99292 21.0301 8.99292 22.6681C8.99292 25.9431 12.7559 30.0371 17.9999 30.0371C23.2439 30.0371 27.0069 25.9431 27.0069 22.6681C27.0069 21.0301 25.9249 20.9231 25.3689 21.0301C22.9349 21.5031 20.9669 21.8491 17.9999 21.8491Z"
        fill="#664500"
      />
      <path
        d="M16.6499 3.28109C15.7909 0.850085 13.1259 -0.425915 10.6939 0.431085C9.21789 0.951085 8.17289 2.14209 7.76589 3.53509C6.57489 2.70609 5.01489 2.43509 3.54089 2.95509C1.11089 3.81309 -0.167107 6.48009 0.691893 8.91109C0.813893 9.25508 0.975893 9.57409 1.16389 9.86909C3.11489 13.4511 8.75189 15.9691 12.1649 16.0001C14.8019 13.8331 17.6109 8.33508 16.8829 4.32308C16.8449 3.97508 16.7699 3.62509 16.6499 3.28109ZM19.3499 3.28109C20.2089 0.850085 22.8749 -0.425915 25.3059 0.431085C26.7819 0.951085 27.8269 2.14209 28.2349 3.53509C29.4259 2.70609 30.9859 2.43509 32.4599 2.95509C34.8899 3.81309 36.1669 6.48009 35.3099 8.91109C35.1869 9.25508 35.0259 9.57409 34.8369 9.86909C32.8859 13.4511 27.2489 15.9691 23.8349 16.0001C21.1979 13.8331 18.3899 8.33508 19.1179 4.32308C19.1549 3.97508 19.2299 3.62509 19.3499 3.28109Z"
        fill="#DD2E44"
      />
    </svg>
  )
}
