import React from 'react'
import { ChapterContainer, ChapterCover, ChapterInfo, ChapterNumber } from './style'
import { Typography } from '../Typography'
import { Chapter as ChapterType } from '../../utils/types'

interface IChapterProps {
  chapter: ChapterType
  onClick: (chapterId: string) => void
}

export const Chapter: React.FC<IChapterProps> = ({ chapter, onClick }) => {
  const handleChapterClick = () => {
    onClick(chapter.id)
  }
  return (
    <ChapterContainer onClick={handleChapterClick}>
      <ChapterCover>
        <img src={chapter.iconUrl || chapter.coverUrl} alt={`chapter ${chapter.order} cover`} />
      </ChapterCover>
      <ChapterInfo>
        <Typography>{`${chapter.order}. ${chapter.title.split(':')[1].trim()}`}</Typography>
        <ChapterNumber>Tour Stop {chapter.order}</ChapterNumber>
      </ChapterInfo>
    </ChapterContainer>
  )
}
