import { createSlice } from '@reduxjs/toolkit'

export interface LoadingState {
  loading: boolean
}

const initialState: LoadingState = {
  loading: true,
}

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    complete: state => {
      state.loading = false
    },
  },
})

export const { complete } = loadingSlice.actions

export default loadingSlice.reducer
