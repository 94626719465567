import React from 'react'

export const HappyEmojiIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
      <path
        d="M18.25 36C28.1911 36 36.25 27.9411 36.25 18C36.25 8.05887 28.1911 0 18.25 0C8.30887 0 0.25 8.05887 0.25 18C0.25 27.9411 8.30887 36 18.25 36Z"
        fill="#FFCC4D"
      />
      <path
        d="M10.7649 23.621C10.8099 23.8 11.9329 28 18.2499 28C24.5679 28 25.6899 23.8 25.7349 23.621C25.7899 23.404 25.6919 23.179 25.4979 23.067C25.3029 22.956 25.0589 22.989 24.8979 23.144C24.8789 23.163 22.9439 25 18.2499 25C13.5559 25 11.6199 23.163 11.6019 23.145C11.5059 23.05 11.3779 23 11.2499 23C11.1659 23 11.0809 23.021 11.0039 23.064C10.8079 23.176 10.7099 23.403 10.7649 23.621Z"
        fill="#664500"
      />
      <path
        d="M12.25 17C13.6307 17 14.75 15.433 14.75 13.5C14.75 11.567 13.6307 10 12.25 10C10.8693 10 9.75 11.567 9.75 13.5C9.75 15.433 10.8693 17 12.25 17Z"
        fill="#664500"
      />
      <path
        d="M24.25 17C25.6307 17 26.75 15.433 26.75 13.5C26.75 11.567 25.6307 10 24.25 10C22.8693 10 21.75 11.567 21.75 13.5C21.75 15.433 22.8693 17 24.25 17Z"
        fill="#664500"
      />
    </svg>
  )
}
