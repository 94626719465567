import React from "react";
import { StyledImage } from "./style";

type ImageProps = {
    source: string;
    alt: string;
}

export function ImageComponent({ source, alt }: ImageProps) {
    return (<StyledImage src={source} alt={alt} />)
}